<template>
  <div class="app_con">
    <app-list :remote="remote" :props="props" :btnFlag="btnFlag" :formProps="formProps" :searchQuery="searchQuery"
      :apiName="apiName" :rules="rules" ref="appList">
      <div class="search" slot="subSlot">


      </div>
    </app-list>

  </div>
</template>

<script>
import api from "@/api/common";

export default {
  data() {

    return {
      rules: {
        price: [
          { required: true, message: "请填写面值", trigger: "blur" },

          { type: "number", message: "金额必须为数字值" },
        ],
        presenterPrice: [
          { required: true, message: "请填写使用门槛", trigger: "blur" },
          { type: "number", message: "金额必须为数字值" },
        ],
      },
      dialogShow: false,
      remote: api,
      apiName: "/topUpPresenter",
      handleType: "",
      searchVal: "",
      form: {},
      btnFlag: { addShow: true, delShow: false },
      searchQuery: {
        siteName: "",
        siteCode: "",
        refreshNum: 0,
        backstage: true,
      },

      props: [


        {
          label: "充值门槛",
          prop: "price",
          align: "center",
        },

        {
          label: "赠送金额",
          prop: "presenterPrice",
          align: "center",
        },

        {
          label: "描述",
          prop: "describeStr",
          align: "center",
        },
        {
          label: "状态",
          prop: "isPutaway",
          align: "center",
          type: 'html',
          formatter: function (row) {
            let code = "";
            if (row.isPutaway == 0) {
              code = "<span class='font-blue'>启用</span>";
            }
            else {
              code = "<span class='font-red'>停用</span>";
            }
            return code;
          },
        },
        {
          label: "发布时间",
          prop: "createTime",
          align: "center",
        },
        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 250,
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "update",
                handler: function () {},
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "remove",               
                handler: function () {
       
                },
              },
            ];
            return buttonList;
          },
        },


      ],
      formProps: [

      {
          label: "充值门槛",
          type: "number",
          prop: "price",
        },
        {
          label: "赠送金额",
          type: "number",
          prop: "presenterPrice",

        },
        {
          label: "描述",
          type: "input",
          prop: "describeStr",
        },
        {
          label: "状态",
          type: "select",
          selectData:[{label:'启用',value:0},{label:'停用',value:1}],
          prop: "isPutaway",
        },
      
      ],
    };
  },
  created() { },
  mounted() { },
  methods: {
    async setCouponPutAway(value) {
      if (!this.$refs.appList.selecTionArr.length) {
        this.$message.error("请至少选择一条数据！");
        return;
      }

      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });

      try {
        let ids = [];
        this.$refs.appList.selecTionArr.forEach((element) => {
          ids.push(element.id);
        });

        let res = await api.commonPost({
          apiName: "/coupon/isPutaway",
          ids: ids,
          isPutaway: value,
        });
        this.$message.success(res.message);
        this.searchQuery.refreshNum++;
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    showAdd() {
      this.form = {};
      this.handleType = "create";
      this.dialogShow = true;
    },

    showEdit(row) {
      this.form = row;

      this.form.validTime = [this.form.validStartTime, this.form.validEndTime];
      this.form.receiveTime = [
        this.form.receiveStartTime,
        this.form.receiveEndTime,
      ];

      this.handleType = "update";
      this.dialogShow = true;
    },

    handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (!(this.form.price < this.form.fullReduction)) {
            this.$message.error("优惠券面额不允许超过门槛金额！");
            return;
          }

          let loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.5)",
          });
          try {
            let obj = Object.assign({}, this.form, {
              apiName: "/coupon/" + this.handleType,
            });
            let res = await api.commonPost(obj);
            this.$message.success(res.message);
            this.searchQuery.refreshNum++;
            this.dialogShow = false;
          } catch (error) {
            console.log(error);
          } finally {
            loading.close();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getReceiveTime(e) {
      console.log(e);

      this.form.receiveStartTime = e[0];
      this.form.receiveEndTime = e[1];
    },

    getValidTime(e) {
      this.form.validStartTime = e[0];
      this.form.validEndTime = e[1];
    },
  },
};
</script>

<style lang="scss" scoped>
.fullWidth {
  width: 100% !important;
}

.changeCon p {
  color: #999;
}

.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}

.search {
  display: flex;
}

.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
